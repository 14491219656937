import React from 'react'
import { useFileContext } from '../context/FileContext.tsx'
import {
    Table,
    Container,
    Card,
    Center,
    Group,
    Divider,
    Space,
    Title,
    Skeleton,
} from '@mantine/core'
import { GridPlayButton } from './GridPlayButton.tsx'

const PieceGridView: React.FC = () => {
    const { parsedPiece } = useFileContext()

    if (!parsedPiece) {
        return (
            <>
                <Container>
                    <Skeleton height={'7vh'} radius={'md'} mt={'sm'} />
                    <Skeleton height={'7vh'} radius={'md'} mt={'sm'} />
                    <Skeleton height={'7vh'} radius={'md'} mt={'sm'} />
                    <Skeleton height={'7vh'} radius={'md'} mt={'sm'} />
                    <Skeleton height={'7vh'} radius={'md'} mt={'sm'} />
                    <Skeleton height={'7vh'} radius={'md'} mt={'sm'} />
                    <Skeleton height={'7vh'} radius={'md'} mt={'sm'} />
                    <Skeleton height={'7vh'} radius={'md'} mt={'sm'} />
                    <Skeleton height={'7vh'} radius={'md'} mt={'sm'} />
                    <Skeleton height={'7vh'} radius={'md'} mt={'sm'} />
                </Container>
            </>
        )
    }

    // Instrument Column titles
    const instruments = Object.keys(
        parsedPiece.instrument_conf.channel_names_by_instrument
    )

    // PlaybackItem name row titles
    const rowNames = parsedPiece.part_names

    const data = rowNames.map((rowName) => (
        <Table.Tr key={rowName}>
            <Table.Td>
                <GridPlayButton
                    playbackItemDesc={{
                        name: rowName,
                        itemtype: 'part',
                        instrument_name: null,
                    }}
                >
                    {rowName}
                </GridPlayButton>
            </Table.Td>
            {instruments.map((instrument) => (
                <Table.Td key={instrument}>
                    {/*{parsedPiece.playback_items[rowName].tracks}*/}
                    {Object.prototype.hasOwnProperty.call(
                        parsedPiece.playback_voices,
                        // @ts-expect-error fix later
                        [rowName, instrument]
                    ) ? (
                        <GridPlayButton
                            playbackItemDesc={{
                                name: rowName,
                                itemtype: 'voice',
                                instrument_name: instrument,
                            }}
                        />
                    ) : (
                        <Card radius={'md'} />
                    )}
                </Table.Td>
            ))}
        </Table.Tr>
    ))

    const sequencecontrols = parsedPiece.sequence_names.map((sequenceName) => (
        <GridPlayButton
            key={sequenceName}
            playbackItemDesc={{
                name: sequenceName,
                itemtype: 'sequence',
                instrument_name: null,
            }}
        >
            {sequenceName}
        </GridPlayButton>
    ))

    return (
        <>
            <Container size="xs">
                <Center>
                    <Title mt="lg" size={20}>
                        {parsedPiece.properties.name}
                    </Title>
                </Center>

                <Center>
                    <Title mt="sm" size={16}>
                        {parsedPiece.properties.subtitle}
                    </Title>
                </Center>

                {/*<Divider*/}
                {/*    m={'lg'}*/}
                {/*    // label={'Sequences'} labelPosition={'right'}*/}
                {/*/>*/}

                {/*<ExternalLinksView />*/}

                <Group mt="lg" justify={'center'}>
                    {sequencecontrols}
                </Group>

                <Divider
                    m={'lg'}
                    // label={'Parts and Voices'}
                    // labelPosition={'right'}
                />

                <Table
                    // stickyHeader
                    // stickyHeaderOffset={60}
                    withRowBorders={false}
                    p={0}
                    verticalSpacing={5}
                    horizontalSpacing={5}
                >
                    {/*<Card radius={'xl'} m={'xl'} p={'xs'}>*/}

                    {/*</Card>*/}
                    <Table.Thead>
                        <Table.Tr>
                            <Table.Th></Table.Th>
                            {instruments.map((instrument) => (
                                <Table.Th key={instrument}>
                                    <Card
                                        // withBorder={true}
                                        radius={'md'}
                                        padding={'xs'}
                                        color={'#455262'}
                                    >
                                        <Center>
                                            {
                                                instrument
                                                    .toLowerCase()
                                                    .startsWith('dj')
                                                    ? instrument.slice(0, 2)
                                                    : instrument
                                                          .charAt(0)
                                                          .toUpperCase()

                                                // (isLargeEnough
                                                //     ? instrument.slice(1)
                                                //     : '')}
                                            }
                                        </Center>
                                    </Card>
                                </Table.Th>
                            ))}
                        </Table.Tr>
                    </Table.Thead>
                    <Table.Tbody>{data}</Table.Tbody>
                </Table>

                <Space h={'lg'} />
            </Container>
        </>
    )
}

export default PieceGridView
