// @ts-expect-error no types for this package
import { saveAs } from 'file-saver'

export function capitalize(s: string | null | undefined) {
    return (s && String(s[0]).toUpperCase() + String(s).slice(1)) || ''
}

// Fallback method to download the file when File System Access API is not available or fails
export function downloadBlob(
    content: string | File,
    mimetype: string,
    filename: string
) {
    const blob = new Blob([content], {
        type: mimetype,
    })
    const url = URL.createObjectURL(blob)
    saveAs(url, filename)
    URL.revokeObjectURL(url)
}

export function fallbackDownloadBlob(url: string, filename: string) {
    const a = document.createElement('a')
    a.href = url
    a.download = filename
    a.target = '_blank'
    document.body.appendChild(a)
    a.click()
    document.body.removeChild(a)
}
