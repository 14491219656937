import {
    Button,
    Center,
    PinInput,
    Slider,
    Space,
    Stack,
    Text,
} from '@mantine/core'
import { useFileContext } from '../context/FileContext.tsx'
import { IconPlayerPlayFilled, IconPlayerStopFilled } from '@tabler/icons-react'
import { capitalize } from '../util.ts'
import { useEffect, useState } from 'react'
// import TransportPositionDisplay from './ToneTransportDisplay.tsx'

export default function RightSidebarContents({
    showPlaybackItemControl,
}: {
    showPlaybackItemControl: boolean
}) {
    const {
        instruments,
        selectedPlaybackItem,
        doPlayAudio,
        setDoPlayAudio,
        bpm,
        setBpm,
        microtimingPattern,
        setMicrotimingPattern,
        microtimingStrength,
        setMicrotimingStrength,
        parsedPiece,
    } = useFileContext()

    // const [isValidPattern, setIsValidPattern] = useState<boolean>(true)
    const [pattern, setPattern] = useState<number[]>(
        parsedPiece?.microtiming_pattern ?? []
    )

    useEffect(() => {
        setPattern(
            parsedPiece?.microtiming_pattern ??
                Array(parsedPiece?.n_pulses_per_beat).fill(1)
        )
    }, [parsedPiece?.microtiming_pattern])

    const playbackItemControl = showPlaybackItemControl ? (
        <>
            <Space h="xl" />
            {/*<Title size="l" fs={!selectedPlaybackItem ? 'italic' : 'normal'}>*/}
            {/*    {selectedPlaybackItem ? (*/}
            {/*        selectedPlaybackItem.name || 'Standard'*/}
            {/*    ) : (*/}
            {/*        <Skeleton height="1rem" />*/}
            {/*    )}*/}
            {/*</Title>*/}
            <Center>
                <Button
                    mt="md"
                    mb="md"
                    size="sm"
                    onClick={() => setDoPlayAudio(!doPlayAudio)}
                    variant="gradient"
                    // variant={doPlayAudio ? 'subtle' : 'gradient'}
                    // color={
                    //     doPlayAudio ? 'var(--bright-color)' : 'var(--bg-color)'
                    // }
                    radius={'md'}
                    leftSection={
                        !doPlayAudio ? (
                            <IconPlayerPlayFilled size={14} />
                        ) : (
                            <IconPlayerStopFilled size={14} />
                        )
                    }
                    disabled={!selectedPlaybackItem}
                >
                    {/*{!doPlayAudio ? 'Play' : 'Stop'}*/}
                    {capitalize(selectedPlaybackItem?.name || 'Standard') +
                        (selectedPlaybackItem?.instrument_name
                            ? ' - ' +
                              capitalize(selectedPlaybackItem?.instrument_name)
                            : '')}
                </Button>
            </Center>
        </>
    ) : (
        <></>
    )

    return (
        <>
            {/*<Group*/}
            {/*    className={classes.header}*/}
            {/*    gap="xs"*/}
            {/*    justify="center"*/}

            {/*    // style={{ width: '100%' }}*/}
            {/*></Group>*/}

            {/*<TransportPositionDisplay />*/}
            {playbackItemControl}
            <Stack
                gap="s"
                style={{
                    paddingLeft: '14px',
                    paddingRight: '14px',
                }}
            >
                {/*<MultiSelect data={parsedPiece?.playback_items} value={selectedPlaybackItem?.element_name} onChange={*/}
                {/*    (value) => {*/}
                {/*        console.log(value)*/}
                {/*        // const newSelectedPlaybackItem = parsedPiece?.playback_items.find((item) => item.element_name === value)*/}
                {/*        // if (newSelectedPlaybackItem) {*/}
                {/*        //     setSelectedPlaybackItem(newSelectedPlaybackItem)*/}
                {/*        // }*/}
                {/*} />*/}

                {/*<Space h="s" />*/}

                <Stack>
                    <Text fw={700}>Tempo</Text>
                    <Slider
                        defaultValue={bpm}
                        // marks={[{ value: 0 }, { value: -30 }]}

                        step={1}
                        min={60}
                        max={170}
                        marks={[
                            { value: 80, label: '80' },
                            { value: 115, label: '115' },
                            { value: 150, label: '150' },
                        ]}
                        label={(value) => `${value} bpm`}
                        onChange={setBpm}
                        value={bpm}
                    />
                </Stack>
                <Space h="l" />

                {/*{microtimingPattern && (*/}
                <Stack>
                    <Text fw={700}>Phrasierung</Text>
                    <Center>
                        <PinInput
                            size="sm"
                            length={parsedPiece?.n_pulses_per_beat}
                            type="number"
                            placeholder=""
                            error={
                                pattern.length !==
                                parsedPiece?.n_pulses_per_beat
                            }
                            value={pattern?.join('')}
                            onChange={(value) => {
                                const updated = value.split('').map(Number)
                                // console.log('updated', updated)
                                setPattern(updated)

                                if (
                                    updated.length ===
                                    parsedPiece?.n_pulses_per_beat
                                ) {
                                    setMicrotimingPattern(updated)
                                }
                            }}
                        />
                    </Center>

                    <Slider
                        defaultValue={microtimingStrength}
                        // marks={[{ value: 0 }, { value: -30 }]}

                        step={0.01}
                        min={0}
                        max={1}
                        marks={[
                            { value: 0.2, label: '20%' },
                            { value: 0.5, label: '50%' },
                            { value: 0.8, label: '80%' },
                        ]}
                        label={(value) => `${Math.round(value * 100)} %`}
                        onChange={setMicrotimingStrength}
                        value={microtimingStrength}
                        disabled={
                            !microtimingPattern ||
                            pattern.length !== parsedPiece?.n_pulses_per_beat
                        }
                    />
                </Stack>
                {/*)}*/}
                <Space h="l" />

                <Text fw={700}>Volume</Text>
                {instruments &&
                    Object.keys(instruments).map((instrumentName) => {
                        const instrument = instruments[instrumentName]
                        return (
                            <Stack gap="xs" key={instrumentName}>
                                <Text
                                    // ml="sm"
                                    size="sm"
                                    // ta="center"
                                    fw={600}
                                    lineClamp={1}
                                >
                                    {instrumentName
                                        .replace(/^h1?_/, '')
                                        .replace(/1/g, ' ')
                                        .replace(/_/g, ' ')
                                        .replace(/\b\w/g, (char) =>
                                            char.toUpperCase()
                                        )}
                                </Text>
                                <Slider
                                    ml="sm"
                                    mr="sm"
                                    defaultValue={
                                        instrument.channel.volume.value
                                    }
                                    marks={[
                                        { value: 0 },
                                        { value: -30 },
                                        {
                                            value: instrument.channel.volume
                                                .value,
                                        },
                                    ]}
                                    step={0.1}
                                    min={-30}
                                    max={6}
                                    label={(value) =>
                                        value === -30
                                            ? '-∞ dB'
                                            : `${value.toFixed(1)} dB`
                                    }
                                    onChange={(value) => {
                                        instrument.channel.volume.value = value
                                        console.log(value, value === -30)
                                        if (value === -30) {
                                            instrument.mute()
                                        } else {
                                            instrument.unmute()
                                        }
                                    }}
                                />
                            </Stack>
                        )
                    })}
                <Space h="l" />

                {/*<Button*/}
                {/*    size="xs"*/}
                {/*    onClick={() => {*/}
                {/*        if (!parsedPiece) return*/}
                {/*        for (const instrumentName in instruments) {*/}
                {/*            const instrument = instruments[instrumentName]*/}
                {/*            instrument.channel.volume.value =*/}
                {/*                parsedPiece.instrument_conf.default_volumes[*/}
                {/*                    instrumentName*/}
                {/*                ]*/}
                {/*        }*/}
                {/*    }}*/}
                {/*>*/}
                {/*    Reset volumes*/}
                {/*</Button>*/}
            </Stack>
        </>
    )
}
