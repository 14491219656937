import React, { useState, useEffect, useRef } from 'react'
import * as Tone from 'tone'
import { Instrument } from './Instrument.tsx'
import deepEqual from 'deep-equal'
import { useFileContext } from './context/FileContext.tsx'
import niTransformOnsets from './core/Microtiming.tsx'
import { useDebouncedValue, usePrevious } from '@mantine/hooks'
import { InstrumentConf, ParsedPiece, PlaybackItem } from './types/ParsedPiece'
import { PlaybackItemDesc } from './types/PlaybackItemDesc'
import WakeLockManager from './components/WakeLock.tsx'

export interface MultiInstrumentPlayerProps {
    parsedPiece: ParsedPiece | null
    selectedPlaybackItemDesc: PlaybackItemDesc | null
    doPlayAudio: boolean
}

const MultiInstrumentPlayer: React.FC<MultiInstrumentPlayerProps> = ({
    parsedPiece,
    doPlayAudio,
    selectedPlaybackItemDesc,
}) => {
    const [parts, setParts] = useState<Tone.Part[] | null>(null)
    const prevSelectedPlaybackItem = useRef<PlaybackItem | null>(null)
    const prevInstrumentConf = useRef<InstrumentConf | null>(null)

    const {
        instruments,
        setInstruments,
        bpm,
        microtimingPattern,
        microtimingStrength,
    } = useFileContext()

    // A ref to our silent audio element
    const silentAudioRef = useRef<HTMLAudioElement>(null)

    useEffect(() => {
        Tone.getTransport().bpm.value = bpm
    }, [bpm])

    const startTone = async () => {
        // Start the WebAudio context
        await Tone.start()
        console.log('Audio context started')

        // Play the silent MP3 to unlock iOS Safari audio
        if (silentAudioRef.current) {
            try {
                silentAudioRef.current.loop = true
                await silentAudioRef.current.play()
                console.log('Silent audio playing')
            } catch (err) {
                console.error('Could not play silent audio:', err)
            }
        }

        document.removeEventListener('click', startTone)
        document.removeEventListener('keydown', startTone)
    }

    useEffect(() => {
        // A user interaction is required on iOS to start audio
        document.addEventListener('click', startTone, { once: true })
        document.addEventListener('keydown', startTone, { once: true })

        return () => {
            document.removeEventListener('click', startTone)
            document.removeEventListener('keydown', startTone)
        }
    }, [])

    useEffect(() => {
        if (!parsedPiece) return

        const instrumentConf = parsedPiece.instrument_conf

        // return if instrumentConf has not changed to avoid reloading instruments
        if (deepEqual(instrumentConf, prevInstrumentConf.current)) return
        prevInstrumentConf.current = instrumentConf

        const loadInstrument = async () => {
            console.log('Loading instruments...')
            const instrumentNames = Object.keys(instrumentConf.instrument_specs)
            const newInstruments: { [key: string]: Instrument } = {}

            for (const instrumentName of instrumentNames) {
                const channelNames =
                    instrumentConf.channel_names_by_instrument[instrumentName]

                for (const channelName of channelNames) {
                    const samplepackName = channelName
                        .split('_')
                        .slice(0, -1)
                        .join('_')
                    const specUrl = `soundsprites/${samplepackName}.json`
                    const audioUrl = `soundsprites/${samplepackName}.webm`

                    const instrumentSpec =
                        instrumentConf.instrument_specs[instrumentName]
                    const samplepack = instrumentSpec.sample_packs.find(
                        (spspeck) => spspeck.id === samplepackName
                    )
                    if (!samplepack) {
                        console.error(
                            'Sample pack not found for channelName:',
                            channelName
                        )
                        continue
                    }

                    const soundmap = samplepack.map
                    const symbol_to_sound_map =
                        instrumentSpec.symbol_to_sound_map

                    const sampleMap = Object.fromEntries(
                        Object.entries(symbol_to_sound_map).map(([k, v]) => [
                            k,
                            soundmap[v].map(String),
                        ])
                    )

                    try {
                        const response = await fetch(specUrl)
                        const spec = await response.json()
                        newInstruments[channelName] = new Instrument(
                            channelName,
                            spec,
                            audioUrl,
                            instrumentConf.default_panning[channelName],
                            instrumentConf.default_volumes[channelName],
                            sampleMap
                        )
                        await newInstruments[channelName].loadPromise
                    } catch (error) {
                        console.error(
                            'Error loading instrument or spec:',
                            error
                        )
                    }
                }
            }
            setInstruments(newInstruments)
        }

        loadInstrument()
    }, [parsedPiece])

    const handlePlayPause = async (
        isPlaybackItemChanged: boolean,
        playbackItem: PlaybackItem
    ) => {
        if (!parsedPiece) return
        if (!playbackItem) {
            Tone.getTransport().stop()
            // Stop the silent audio if it's playing
            if (silentAudioRef.current && !silentAudioRef.current.paused) {
                silentAudioRef.current.pause()
                silentAudioRef.current.currentTime = 0
            }
            return
        }
        if (!instruments) return

        // update parts if playback item has changed
        if (isPlaybackItemChanged || !parts) {
            // stop all parts
            parts?.forEach((part) => part.stop())

            Tone.getTransport().timeSignature = parsedPiece.n_pulses_per_beat

            const newParts: Tone.Part[] = []
            for (const instrumentId in playbackItem.tracks) {
                for (const track of playbackItem.tracks[instrumentId]) {
                    if (!instruments[instrumentId]) return
                    await instruments[instrumentId].loadPromise
                    const transformedTimes = microtimingPattern
                        ? niTransformOnsets(
                              track.times,
                              microtimingPattern,
                              microtimingStrength
                          )
                        : track.times

                    const part = await instruments[instrumentId].create_part(
                        transformedTimes,
                        track.sounds,
                        track.length
                    )
                    newParts.push(part)
                }
            }
            setParts(newParts)
            newParts.forEach((part) => part.start(0))
        }

        if (!doPlayAudio) {
            Tone.getTransport().stop()
            // Stop silent audio if it's playing
            if (silentAudioRef.current && !silentAudioRef.current.paused) {
                silentAudioRef.current.pause()
                silentAudioRef.current.currentTime = 0
            }
        } else {
            if (Tone.getTransport().state !== 'started') {
                // await Tone.getContext().resume()
                // if (Tone.getContext().state !== 'running') {
                //     setAudioContextActive(false)
                // }

                let start_pulse = parsedPiece.properties.start_pulse
                if (start_pulse < 0) {
                    start_pulse += playbackItem.length
                }

                // Keep silent audio playing while your main playback runs
                if (silentAudioRef.current && silentAudioRef.current.paused) {
                    silentAudioRef.current.loop = true
                    try {
                        await silentAudioRef.current.play()
                    } catch (err) {
                        console.error('Error resuming silent audio:', err)
                    }
                }

                const start_time = `0:0:${start_pulse}`
                Tone.getTransport().start('+0', start_time)
            }
        }
    }

    const [debouncedMicrotimingStrength] = useDebouncedValue(
        microtimingStrength,
        50
    )
    const prevMicrotimingPattern = usePrevious(microtimingPattern)
    const prevMicrotimingStrength = usePrevious(debouncedMicrotimingStrength)

    useEffect(() => {
        if (!parsedPiece || !selectedPlaybackItemDesc) return

        const playbackItem =
            parsedPiece.playback_items.find(
                (item) =>
                    item.name === selectedPlaybackItemDesc.name &&
                    item.itemtype === selectedPlaybackItemDesc.itemtype &&
                    item.instrument_name ===
                        selectedPlaybackItemDesc.instrument_name
            ) ??
            parsedPiece.playback_items.find(
                (item) => item.element_name === 'Standard'
            )

        if (!playbackItem) {
            console.error(
                'No playback item found for selectedPlaybackItemDesc:',
                selectedPlaybackItemDesc
            )
            return
        }

        const isSelectedPlaybackItemDescChanged =
            playbackItem !== prevSelectedPlaybackItem.current ||
            prevMicrotimingPattern !== microtimingPattern ||
            prevMicrotimingStrength !== debouncedMicrotimingStrength

        prevSelectedPlaybackItem.current = playbackItem

        handlePlayPause(isSelectedPlaybackItemDescChanged, playbackItem)
    }, [
        selectedPlaybackItemDesc,
        parsedPiece,
        doPlayAudio,
        debouncedMicrotimingStrength,
        microtimingPattern,
    ])

    return (
        <>
            {/* Silent audio element */}
            <audio ref={silentAudioRef} src="/silence_1s.mp3" />
            <WakeLockManager />
            {/* No visible UI needed here */}
        </>
    )
}

export default MultiInstrumentPlayer
