import { Button, rem } from '@mantine/core'
import { IconCheck, IconDownload } from '@tabler/icons-react'
import { useFileContext } from '../context/FileContext.tsx'
import { downloadBlob } from '../util.ts'
import { notifications } from '@mantine/notifications'

function SaveFileButton() {
    const { editorContent, parsedPiece } = useFileContext()
    // Function to save file using File System Access API or fallback
    const saveFile = async () => {
        try {
            const options = {
                types: [
                    {
                        description: 'Text File',
                        accept: { 'text/plain': ['.txt', '.md'] },
                    },
                ],
                suggestedName: parsedPiece?.properties.filename,
            }

            // Check for support and open save file picker
            if ('showSaveFilePicker' in window) {
                // @ts-expect-error for now
                const fileHandle = await window.showSaveFilePicker(options)
                const writable = await fileHandle.createWritable()
                await writable.write(editorContent)
                await writable.close()
                notifications.show({
                    title: 'Success!',
                    message: 'The file was saved successfully!',
                    icon: (
                        <IconCheck
                            style={{ width: rem(18), height: rem(18) }}
                        />
                    ),
                    loading: false,
                })
            } else {
                // Fallback method if File System Access API is not available
                downloadBlob(
                    editorContent,
                    'text/plain;charset=utf-8',
                    parsedPiece?.properties.filename ?? 'untitled.txt'
                )
            }
        } catch (error) {
            // notifications.show({
            //     title: 'Error!',
            //     message: 'Failed to save file...',
            //     icon: <IconCross style={{ width: rem(18), height: rem(18) }} />,
            //     loading: false,
            // })
            // console.error('Failed to save file:', error)
            // Fallback method if there was an error using the File System Access API
            // fallbackSave()
        }
    }

    return (
        <Button
            onClick={() => saveFile()}
            leftSection={<IconDownload size={14} />}
            variant="light"
            radius="md"
            color="var(--bright-color)"
            size="sm"
        >
            Save
        </Button>
    )
}

export default SaveFileButton
