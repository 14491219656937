import CodeMirror, { ChangeSet, EditorState } from '@uiw/react-codemirror'
import { hyperLink } from '@uiw/codemirror-extensions-hyper-link'
import { yamlFrontmatter } from '@codemirror/lang-yaml'
import { markdown } from '@codemirror/lang-markdown'
import { useFileContext } from '../context/FileContext.tsx'
// import { autocompletion, CompletionContext } from '@codemirror/autocomplete'
import React from 'react'

export function CodeEditor() {
    const { editorContent, setEditorContent, setSelectedLine } =
        useFileContext()

    const onChange = React.useCallback((val: string) => {
        setEditorContent(val)
    }, [])

    // Define the transactionFilter to suppress auto-period
    const suppressAutoPeriod = EditorState.transactionFilter.of(
        (transaction) => {
            if (!transaction.isUserEvent('input.type')) return transaction

            let changes = null
            transaction.changes.iterChanges(
                (fromA, _toA, _fromB, _toB, inserted) => {
                    console.log(inserted.length, inserted.toString())
                    if (
                        inserted.length === 2 && // Single chunk of inserted text
                        inserted.toString() === '. ' // The inserted text is ". "
                    ) {
                        // Construct a new ChangeSet to replace ". " with " "
                        changes = ChangeSet.of(
                            [{ from: fromA, insert: ' ' }],
                            transaction.startState.doc.length
                        )
                    }
                }
            )

            // If a modification is needed, return a new transaction spec with the updated changes
            if (changes) {
                return { changes }
            }

            // Otherwise, allow the transaction to proceed
            return transaction
        }
    )

    //
    // function myCompletions(context: CompletionContext) {
    //     // determine if we are currently in the front matter (within lines --- --- ) or not
    //     // if we are in the front matter, we should only suggest properties and values
    //     // if we are not in the front matter, we should suggest playback items and instrument names
    //
    //     // const line = context.state.doc.lineAt(context.pos)
    //
    //     const completions = []
    //
    //     const before = context.matchBefore(/\w+/)
    //
    //     // If completion wasn't explicitly started and there
    //     // is no word before the cursor, don't open completions.
    //     if (!context.explicit && !before) return null
    //
    //     // Determine if we are in the yamlFrontmatter or markdown part
    //
    //     // check if before the current line, there exists only a single line with "---"
    //     // if so, we are in the front matter
    //     const frontmatterlines = context.state.doc
    //         .sliceString(0, context.pos)
    //         .match(/---/g)
    //
    //     if (frontmatterlines && frontmatterlines.length === 1) {
    //         const propertyNames = parsedPiece
    //             ? Object.keys(parsedPiece.properties || {})
    //             : []
    //         const propertyCompletions = propertyNames.map((prop) => ({
    //             label: prop,
    //             type: 'property',
    //         }))
    //         completions.push(...propertyCompletions)
    //         return {
    //             from: before ? before.from : context.pos,
    //             options: completions,
    //             validFor: /[^\s]\w*$/,
    //         }
    //     }
    //
    //     // check if the at the current line, the text before before is either "#" or "# "
    //     const isHeadingFirstElement = context.matchBefore(/^#.*/)
    //
    //     if (isHeadingFirstElement) {
    //         const playbackItemNames = parsedPiece
    //             ? parsedPiece.playback_items.map((item) => item.name)
    //             : []
    //
    //         const instrumentNames = parsedPiece
    //             ? parsedPiece.instrument_names
    //             : []
    //
    //         const playbackItemCompletions = playbackItemNames.map((prop) => ({
    //             label: prop,
    //             type: 'property',
    //         }))
    //
    //         const instrumentCompletions = instrumentNames.map((prop) => ({
    //             label: prop,
    //             type: 'property',
    //         }))
    //
    //         completions.push(...playbackItemCompletions)
    //         completions.push(...instrumentCompletions)
    //         console.log(before)
    //         return {
    //             from: before ? before.from : context.pos,
    //             options: completions,
    //             validFor: /^\w*$/,
    //         }
    //     }
    // }

    return (
        <CodeMirror
            value={editorContent}
            extensions={[
                hyperLink,
                yamlFrontmatter({ content: markdown() }),
                suppressAutoPeriod,
                // autocompletion({
                //     override: [myCompletions],
                //     activateOnTyping: true,
                // }),
            ]}
            onChange={onChange}
            onStatistics={(s) => {
                if (!s.selectedText) setSelectedLine(s.line.number - 1)
            }}
            theme={'dark'}
        />
    )
}
