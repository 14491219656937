import React, { createContext, useContext, useState } from 'react'
import { initial_editor_content } from '../IntialEditorContent.tsx'
import { useDebouncedValue, useDisclosure } from '@mantine/hooks'
import { Instrument } from '../Instrument.tsx'
import * as Tone from 'tone'

// Define the type for the context state
interface FileContextType {
    editorContent: string
    setEditorContent: (text: string) => void
    fileName: string
    setFileName: (fileName: string) => void
    debouncedEditorContent: string
    parsedPiece: ParsedPiece | null
    setParsedPiece: (piece: ParsedPiece | null) => void
    selectedLine: number
    setSelectedLine: (line: number) => void
    selectedPlaybackItem: PlaybackItemDesc | null
    setSelectedPlaybackItem: (item: PlaybackItemDesc | null) => void
    doPlayAudio: boolean
    setDoPlayAudio: (val: boolean) => void
    pdfUrl: string | null
    setPdfUrl: (url: string | null) => void
    instruments: { [key: string]: Instrument }
    setInstruments: (instruments: { [key: string]: Instrument }) => void
    bpm: number
    setBpm: (bpm: number) => void
    microtimingStrength: number
    setMicrotimingStrength: (strength: number) => void
    microtimingPattern: number[] | null
    setMicrotimingPattern: (pattern: number[] | null) => void
    activePieceView: string | null
    setActivePieceView: (view: string | null) => void
    activeLeftPieceView: string
    setActiveLeftPieceView: (view: string) => void
    mobileOpened: boolean
    toggleMobile: () => void
    closeMobile: () => void
}

// Create the context with a default value
const FileContext = createContext<FileContextType | undefined>(undefined)

import { useSessionStorage } from '@mantine/hooks'
import { ParsedPiece } from '../types/ParsedPiece'
import { PlaybackItemDesc } from '../types/PlaybackItemDesc'
// Custom hook to use the file context
export function useFileContext() {
    const context = useContext(FileContext)
    if (!context)
        throw new Error('useFileContext must be used within a FileProvider')
    return context
}

// Provider component
export const FileProvider: React.FC<{ children: React.ReactNode }> = ({
    children,
}) => {
    const [fileName, setFileName] = useState<string>('piece.txt')

    const [editorContent, setEditorContent] = useSessionStorage({
        key: 'editor-content',
        defaultValue: initial_editor_content,
    })
    // const [editorContent, setEditorContent] = React.useState(
    //     initial_editor_content
    // )
    const [debouncedEditorContent] = useDebouncedValue(editorContent, 300)

    const [parsedPiece, setParsedPiece] = useState<ParsedPiece | null>(null)

    // const [theme, setTheme] = useState('dark')

    const [selectedLine, setSelectedLine] = useState<number>(0)

    const [selectedPlaybackItem, setSelectedPlaybackItem] =
        useState<PlaybackItemDesc | null>(null)

    const [doPlayAudio, internalSetDoPlayAudio] = useState<boolean>(false)

    const [pdfUrl, setPdfUrl] = useState<string | null>(null)

    const [instruments, setInstruments] = useState<{
        [key: string]: Instrument
    }>({})

    const [bpm, setBpm] = useState<number>(115)

    const [microtimingPattern, setMicrotimingPattern] = useState<
        number[] | null
    >(null)
    const [microtimingStrength, setMicrotimingStrength] = useState<number>(0.0)

    const [activePieceView, setActivePieceView] = useState<string | null>(
        'piecegrid'
    )

    const [activeLeftPieceView, setActiveLeftPieceView] =
        useState<string>('preview')

    const [mobileOpened, { toggle: toggleMobile, close: closeMobile }] =
        useDisclosure()

    function setDoPlayAudio(val: boolean) {
        if (val && Tone.getContext().state !== 'running') {
            Tone.getContext()
                .resume()
                .then(() => {
                    console.log('Audio context resumed')
                })
                .catch((err) => {
                    console.error('Error resuming audio context:', err)
                })
                .finally(() => {
                    // internalSetDoPlayAudio(val)
                })
            internalSetDoPlayAudio(val)
        } else {
            internalSetDoPlayAudio(val)
        }
    }

    return (
        <FileContext.Provider
            value={{
                editorContent,
                setEditorContent,
                fileName,
                setFileName,
                debouncedEditorContent,
                parsedPiece,
                setParsedPiece,
                selectedLine,
                setSelectedLine,
                selectedPlaybackItem,
                setSelectedPlaybackItem,
                doPlayAudio,
                setDoPlayAudio,
                pdfUrl,
                setPdfUrl,
                instruments,
                setInstruments,
                bpm,
                setBpm,
                microtimingPattern,
                setMicrotimingPattern,
                microtimingStrength,
                setMicrotimingStrength,
                activePieceView,
                setActivePieceView,
                activeLeftPieceView,
                setActiveLeftPieceView,
                mobileOpened,
                toggleMobile,
                closeMobile,
            }}
        >
            {children}
        </FileContext.Provider>
    )
}
