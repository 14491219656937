import React, { useEffect, useRef } from 'react'
import { useFileContext } from '../context/FileContext.tsx'
// Replace this import with your actual context:

const WakeLockManager: React.FC = () => {
    const { doPlayAudio } = useFileContext()
    const wakeLockRef = useRef<WakeLockSentinel | null>(null)

    const startWakeLock = async () => {
        try {
            // Check if the Wake Lock API is available
            if ('wakeLock' in navigator && navigator.wakeLock.request) {
                wakeLockRef.current = await navigator.wakeLock.request('screen')
                // console.log('Wake Lock is active')
            }
        } catch (err: any) {
            // console.error(`Could not obtain wake lock: ${err.message}`)
        }
    }

    const stopWakeLock = () => {
        if (wakeLockRef.current) {
            wakeLockRef.current.release()
            wakeLockRef.current = null
            // console.log('Wake Lock has been released')
        }
    }

    useEffect(() => {
        // console.log(
        //     'doPlayAudio:',
        //     doPlayAudio,
        //     'wakeLockRef:',
        //     wakeLockRef.current
        // )
        if (doPlayAudio) {
            startWakeLock()
        } else {
            stopWakeLock()
        }

        return () => {
            // Cleanup if component unmounts
            stopWakeLock()
        }
    }, [doPlayAudio])

    return null
}

export default WakeLockManager
