// import React from 'react' // Assuming you're using Tabler icons
import { Button } from '@mantine/core'
import { IconDownload } from '@tabler/icons-react'
import { useFileContext } from '../context/FileContext.tsx'
import { fallbackDownloadBlob } from '../util.ts'

function ExportPDFButton() {
    // use state pdfURL from FileContext
    const { pdfUrl, parsedPiece } = useFileContext()
    //
    // const saveFile = async () => {
    //     if (!pdfUrl) {
    //         return
    //     }
    //     try {
    //         const options = {
    //             types: [
    //                 {
    //                     description: 'PDF File',
    //                     accept: { 'application/pdf': ['.pdf'] },
    //                 },
    //             ],
    //             suggestedName: parsedPiece?.properties.filename,
    //         }
    //
    //         // Check for support and open save file picker
    //         if ('showSaveFilePicker' in window) {
    //             // @ts-expect-error for now
    //             const fileHandle = await window.showSaveFilePicker(options)
    //             const writable = await fileHandle.createWritable()
    //
    //             const blob = new Blob([pdfUrl], {
    //                 type: 'application/pdf',
    //             })
    //
    //             await writable.write(blob)
    //             await writable.close()
    //             alert('File saved successfully!')
    //         } else {
    //             // Fallback method if File System Access API is not available
    //             // downloadBlob(
    //             //     pdfUrl,
    //             //     'application/pdf',
    //             //     parsedPiece?.properties.filename ?? 'untitled.pdf'
    //             // )
    //
    //             fallbackDownloadBlob(
    //                 pdfUrl,
    //                 parsedPiece?.properties.filename + '.pdf'
    //             )
    //         }
    //     } catch (error) {
    //         console.error('Failed to save file:', error)
    //         // Fallback method if there was an error using the File System Access API
    //         // fallbackSave()
    //     }
    // }

    // saveFile()

    // // Fallback method to download the file when File System Access API is not available or fails
    // const openPDF = () => {
    //     if (!pdfUrl) {
    //         return
    //     }
    //     const sharePDF = async () => {
    //         // try {
    //         //     const response = await fetch(pdfUrl)
    //         //     const buffer = await response.arrayBuffer()
    //         //
    //         //     const pdf = new File(
    //         //         [buffer],
    //         //         parsedPiece?.properties.filename + '.pdf',
    //         //         {
    //         //             type: 'application/pdf',
    //         //         }
    //         //     )
    //         //     const files = [pdf]
    //         //
    //         //     if (navigator.canShare({ files })) {
    //         //         await navigator.share({ files })
    //         //     } else {
    //         //         downloadBlob(
    //         //             pdf,
    //         //             'application/pdf',
    //         //             parsedPiece?.properties.filename + '.pdf'
    //         //         )
    //         //     }
    //         // } catch (error) {
    //         //     console.error('Error sharing PDF:', error)
    //             fallbackDownloadBlob(
    //                 pdfUrl,
    //                 parsedPiece?.properties.filename + '.pdf'
    //             )
    //         // }
    //     // }
    //
    //
    //     sharePDF()
    // }

    return (
        <Button
            onClick={() =>
                pdfUrl &&
                fallbackDownloadBlob(
                    pdfUrl,
                    parsedPiece?.properties.filename + '.pdf'
                )
            }
            leftSection={<IconDownload size={14} />}
            variant="light"
            radius="md"
            // color="var(--bright-color)"
            size="sm"
            disabled={!pdfUrl}
        >
            Save PDF
        </Button>
    )
}

export default ExportPDFButton
